import React, { RefObject } from 'react';
import { Chart } from './chart'

interface IProps {
  name: string
}
class ChartComponent extends React.Component<IProps> {
  public myRef: RefObject<any>

  constructor (props: IProps) {
    super(props)
    this.myRef = React.createRef()
  }

  componentDidMount() {
    new Chart({
      name: this.props.name, 
      el: this.myRef.current
    });
  }

  render() {
    return (
      <div className="col-md-10 col-md-offset-1">
        <div ref={this.myRef} className="col-md-12 chart">
        </div>
        <div className="col-md-10 col-md-offset-1 explainer">
          INTERACTIVE CHART: Resize and Drag the box at the bottom of the chart above to zoom in or out on the data, and to change the date range covered. Hover over individual data points to see the average daily PM2.5 and PM10 levels on that date. (Note: Straight lines with no hover points indicate that no reading was taken on that day)
        </div>
      </div>
    );
  }
}

export default ChartComponent;
