import React from 'react';
import Section from './common/section';
import Heading from './common/heading';
import Image from './common/image';
import Desc from './common/desc';
import Credit from './common/credit';

const TheEffect: React.FC = () => {
  return (
    <Section id="theeffect">
      <Heading>The Effect: The changing face of disease in Kenya</Heading>
      <Desc>
        The devastating effect of air pollution is already being manifested in health.
      </Desc>
      <Desc>
        In 2011, respiratory ailments overtook malaria to become the number one disease incidence in the country. 
      </Desc>
      <Image fileName="Resp vs Malaria.jpg"></Image>
      <Credit text="Source: Economic Survey 2018">
      </Credit>
      <Desc>
        Respiratory ailments are the most common diseases in local health facilities. In 2017, they accounted for 34 per cent of all incidents, compared with 18.7 per cent for malaria.
      </Desc>
      <Desc>
        According to the UN, about 14,300 Kenyans die annually due to health conditions that are traceable to indoor air pollution. The most prominent of these conditions is pneumonia. The Kenya National Bureau of Statistics ranks it as the top killer disease in the country, having dislodged malaria from that position in 2015. 
      </Desc>
      <Desc>
        Road traffic is the number one cause of air pollution in Nairobi, with the UN estimating that about 40 per cent of Nairobi’s air pollution is caused by vehicles.
      </Desc>
      <Desc>
        A study published in 2015 found high levels of exposure to black carbon, particularly among matatu drivers. Matatu drivers experience up to five times the levels of their fellow bus drivers in European cities. Of all roadside jobs identified in the study, matatu driving was the most dangerous.
      </Desc>
      <Desc>
        Idling the engine, especially, has been seen to be a major cause of air pollution.
      </Desc>
      <Desc>
        <div className="quote">
          “When we leave the engine on, it makes it look like we’re leaving so we can get customers faster that way. In fact, we rarely turn the matatu off,” said Kevin Maina, a matatu conductor. 
        </div>
      </Desc>
      <Desc>
        The majority of air pollution victims are women and minors. In 2015, 22,473 people died of pneumonia, and most of them were <a href="https://resourcecentre.savethechildren.net/node/12479/pdf/fighting_for_breath_-_africa_cover.pdf" target="_blank">children </a> below the age of two. 
      </Desc>
      <Desc>
        Women are more exposed to air pollution–related diseases in Kenya because they are primarily involved in cooking and household work, which involves high exposure to smoke and dust.
      </Desc>
      <Desc>
        The insurance industry released data showing that respiratory diseases are the most common source of insurance claims in Kenya, and reports <a href="https://echa.europa.eu/documents/10162/22816069/rana_roy_day1_presentation_en.pdf/e3ad8f43-73b1-4536-a58e-4e863f8b2396" target="_blank">indicate</a> that the country loses up to Sh200 billion due to premature deaths caused by air pollution. 
      </Desc>
      <Image fileName="Premature Deaths.png"></Image>
      <Desc>
        Already struggling with its current disease load, Kenya’s healthcare system seems to be particularly vulnerable to the effects of air pollution. We may see much more devastation in years to come if something drastic is not done soon. 
      </Desc>
    </Section>
  )
}

export default TheEffect;
