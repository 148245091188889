import React from 'react';
import Section from './common/section';
import Heading from './common/heading';
import Image from './common/image';
import Desc from './common/desc';
import Credit from './common/credit';

const HowBad: React.FC = () => {
  return (
    <Section id="howbad">
      <Heading>How bad is air pollution in Kenya?</Heading>
      <Desc>
        According to <a href="http://documents.worldbank.org/curated/en/239271500275879803/pdf/117480-PUB-Date-6-29-2017-PUBLIC.pdf" target="_blank">the World Bank</a>, 99 per cent of Kenyans are exposed to levels of air pollution exceeding WHO safety guidelines. Kenya’s current PM2.5 level is at 16 – 1.6 times above the safety limit, according to the UN.
      </Desc>
      <Image fileName="Exposed.png"></Image>
      <Desc>
        We conducted a study of air pollution in Nairobi. Here's what we found.
      </Desc>
    </Section>
  )
}

export default HowBad;
