import React from 'react';

const Section: React.FC<{id: string}> = (props) => {
  return (
    <section id={props.id} className="wow fadeInUp" data-wow-delay="0.3s">
      <div className="container fix-width">
        <div className="row">
          {props.children}
        </div>
      </div>
    </section>
  )
}

export default Section;