import { IItem, IMargin } from './interface';
import { getXAxisTickValues, getMaxYTick, getYAxisTickValues } from './helper';
import { ElChart } from './elchart';

declare let d3: any;
declare let $: any;

interface IProps {
  data: IItem[],
  el: any;
  svg: any,
  margin: IMargin,
  color: string,
  showTicks: boolean,
  goodValue: number,
  unhealthyValue: number,
  lineColor: string
}

export class LineChart extends ElChart {
  private props: IProps;
  private focus: any = null;
  
  private x: any = null;
  private y: any = null;

  private xAxis: any = null;
  private yAxis: any = null;
  
  private height: number = 0;
  private width: number = 0;

  private yTickValues: number[] = [];
  private maxYTick: number = 0;

  private div: any;

  constructor(props: IProps) {
    super(props.el);

    this.props = props;
    this.init();
  }

  private init() {
    let { margin, data, svg } = this.props;

    this.width = this.elWidth - margin.left - margin.right;
    this.height = this.elHeight - margin.top - margin.bottom;

    this.yTickValues = getYAxisTickValues(data);
    this.maxYTick = getMaxYTick(data);
    
    this.x = d3.scale.ordinal().rangeBands([0, this.width], .1);
    this.y = d3.scale.linear().range([this.height, 0]);
    
    this.xAxis = d3.svg.axis().scale(this.x).orient("bottom").tickValues([]);;
    this.yAxis = d3.svg.axis().scale(this.y).orient("left");

    this.x.domain(data.map((d) => { return d.date}));
    this.y.domain([0, this.maxYTick]);
        
    this.focus = svg.append("g")
        .attr("class", "focus")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
      
    this.focus.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + this.height + ")")
        .call(this.xAxis);

    this.focus.append("g")
        .attr("class", "y axis")
        .call(this.yAxis);

    this.div = d3.select("body").append("div")	
        .attr("class", "tooltip")				
        .style("opacity", 0);

    this.enter()
    this.updateScale()
  } 

  private getHealthyData() {
    let { goodValue, unhealthyValue } = this.props
    return [goodValue, goodValue, unhealthyValue, unhealthyValue];
  }

  private updateScale(brushValue: number = 0) { 
    let { showTicks, data } = this.props
    let xTickValues: string[] = [];
    if(showTicks) {
      xTickValues = getXAxisTickValues(data, brushValue, this.width);
    }

    this.focus.select(".x.axis").call(this.xAxis.tickValues(xTickValues));
    this.focus.select(".y.axis").call(this.yAxis.tickValues(this.yTickValues));
  }
      
  private update()
  {
    let { data } = this.props;

    this.x.domain(data.map((d) => { return d.date}));
    this.y.domain([0, this.maxYTick]);

    this.updateBars();
  }

  private updateBars() {
    let { data, lineColor } = this.props;

    let lines = this.focus.selectAll('.line').data(this.yTickValues);
    lines
      .attr(
      {  
        x1: (d: number, i: number) => { 
          return 0;
        },
        y1: (d: number) => { 
          return this.y(d)
        },
        x2: (d: number) => {
          return this.width;
        },
        y2: (d: number) => {
          return this.y(d)
        }
      })

    let path = this.focus.selectAll('path').datum(data)
    path.attr("d", d3.svg.area()
      .x((d: IItem) => { return this.x(d.date) })
      .y0(this.y(0))
      .y1((d: IItem) => { return this.y(d.value) })
    )

    let bars = this.focus.selectAll('.bar').data(data)
    bars.attr(
      {
        height: (d: IItem) => {
          return this.height;
        },
        width: (d: IItem) => { 
          return this.x.rangeBand();
        },
        x: (d: IItem) => {
          return this.x(d.date);
        },
        y: (d: IItem) => {
          return 0;
        }
      })	
      .on("mouseover", (d: IItem) => {		
        $(".tooltip").css("opacity", 0)
        this.div.style("opacity", .9);		
        this.div.html("<b>Date: </b>" + d.date + "<br/><b>Value: </b>" + d.value.toFixed(2))	
            .style("left", (d3.event.pageX) + "px")		
            .style("top", (d3.event.pageY - 28) + "px");	
        })

    let healthData = this.getHealthyData();
    let healthLines = this.focus.selectAll('.healthLine').data(healthData);
    healthLines
      .attr(
      {  
        x1: (d: number, i: number) => { 
          return 0;
        },
        y1: (d: number) => { 
          return this.y(d)
        },
        x2: (d: number) => {
          return this.width;
        },
        y2: (d: number) => {
          return this.y(d)
        },
        stroke: (d: number) => {
          return d > 0 && d < this.maxYTick ? lineColor : "transparent"
        }
      })

    let healthText = this.focus.selectAll('.healthText').data(healthData);
    let { goodValue, unhealthyValue } = this.props
    healthText
      .attr(
      {  
        x: (d: number, i: number) => { 
          return this.width;
        },
        y: (d: number, index: number) => { 
          let offset = 0;
          if(this.y(goodValue) - this.y(unhealthyValue) < 15) {
            if(index < 2) {
              offset = 10;
            } else {
              offset = -10;
            }
          }
          return this.y(d) + (index % 2 === 0 ? -4 : 8) + offset;
        }
      }).text( (d: number, index: number) => {
        if(d > 0 && d < this.maxYTick) {
          if(index / 2 < 1) {
            if(index % 2 === 0) {
              return "Below this";
            } else {
              return "is good";
            }
          } else {
            if(index % 2 === 0) {
              return "Above this";
            } else {
              return "is unhealthy";
            }
          }
        }

        return "";
      })
  }

  private exit()
  {
    let { data } = this.props;

    let lines = this.focus.selectAll('.line').data(this.yTickValues);
    lines.remove();

    let path = this.focus.selectAll('path').datum(data);
    path.remove();

    let bars = this.focus.selectAll('.bar').data(data);
    bars.remove();

    let healthData = this.getHealthyData();
    let healthLines = this.focus.selectAll('.healthLine').data(healthData);
    healthLines.remove();

    let healthText = this.focus.selectAll('.healthText').data(healthData);
    healthText.remove();
  }
    
  private enter()
  {
    let { data, color } = this.props;
    this.x.domain(data.map((d) => { return d.date}));
    this.y.domain([0, this.maxYTick]);

    let lines = this.focus.selectAll('.line').data(this.yTickValues);
    lines.enter().append("line")
      .classed('line', true);

    this.focus.append("path")
      .datum(data)
      .attr("fill", color)
      .attr("stroke", color)
      .attr("stroke-width", 0)

    // Add the scatterplot
    let bars = this.focus.selectAll('.bar').data(data);
    bars.enter()
      .append("rect")
      .classed('bar', true);

    let healthData = this.getHealthyData();
    let healthLines = this.focus.selectAll('.healthLine').data(healthData);
    healthLines.enter().append("line")
      .classed('healthLine', true);

    let healthText = this.focus.selectAll('.healthText').data(healthData);
    healthText.enter().append("text")
      .classed('healthText', true);

    this.updateBars();
  }

  applyBrush(data: IItem[]) {
    this.props.data = data;
    this.resize();
  }

  resize() {
    let { margin } = this.props;

    this.width = this.elWidth - margin.left - margin.right;
    this.height = this.elHeight - margin.top - margin.bottom;

    this.x.rangeBands([0, this.width], .1);
    this.y.range([this.height, 0]);

    this.update();
    this.exit();
    this.enter();
    this.updateScale();
  }
}