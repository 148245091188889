import React from 'react';

const SubHeading: React.FC<{title: string, img: string}> = (props) => {
  return (
    <div className="col-md-10 col-md-offset-1 sub-heading">
      <div className="row">
        <div className="col-sm-6 title">{props.title}</div>
        <div className="col-sm-6 image">
          <img src={"/assets/images/" + props.img} alt={props.img}></img>
        </div>
      </div>
    </div>
  )
}

export default SubHeading;