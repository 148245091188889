import React from 'react';

const Nav: React.FC = () => {
  return (
    <nav id="navbar">
      <div>Quick Links</div>
      <ul>
        <li><a href="#why">Why is air pollution important?</a></li>
        <li><a href="#polution101">Air pollution 101</a></li>
        <li><a href="#howbad">How bad is air pollution in Kenya?</a></li>
        <li><a href="#nairobi"> - Nairobi</a></li>
        <li><a href="#syokimau"> - Syokimau</a></li>
        <li><a href="#langata"> - Langata</a></li>
        <li><a href="#mukuru"> - Mukuru</a></li>
        <li><a href="#theeffect">The effect: The changing face of disease in Kenya</a></li>
      </ul>
    </nav>
  )
}

export default Nav;