import { IItem, IMargin } from './interface';
import { getXAxisTickValues } from './helper';
import { ElChart } from './elchart';

declare let d3: any;

interface IProps {
  data: IItem[], 
  el: any,
  svg: any, 
  margin: IMargin, 
  onBrushed: Function
}

export class Brush extends ElChart {
  private props: IProps;

  private context: any = null;
  private brush: any = null;
  
  private x: any = null;
  private y: any = null;

  private xAxis: any = null;
  
  private height: number = 0;
  private width: number = 0;

  private prevExtent: any = null;

  constructor(props: IProps) {
    super(props.el);

    this.props = props;
    this.init();
  }

  private init() {
    let { margin, data, onBrushed, svg } = this.props

    this.width = this.elWidth - margin.left - margin.right;
    this.height = this.elHeight - margin.top - margin.bottom;

    this.x = d3.scale.ordinal().rangeBands([0, this.width], .1);
    this.y = d3.scale.linear().range([this.height, 0]);

    this.xAxis = d3.svg.axis().scale(this.x).orient("bottom");

    this.x.domain(data.map((d) => { return d.date}));
    this.y.domain([0, d3.max(data, (d: IItem) => { return d.value;})]);
        
    this.brush = d3.svg.brush()
        .extent([[0,0], [100,500]])
        .x(this.x) 
        .on("brush", () => {
          let brush: number[] = this.brushed();
          
          if(brush.length === 0) {
            if(this.prevExtent) {
              this.drawBrush(this.prevExtent[0], this.prevExtent[1]);
            }

            return false;
          }

          this.prevExtent = this.brush.extent();
          onBrushed(brush);
        });

    this.drawBrush(0, this.width);

    this.context = svg.append("g")
        .attr("class", "context")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    this.updateScale();
    this.subChart();
  }

  private drawBrush(min: number, max: number) {
    this.brush.extent([min, max])

    // now draw the brush to match our extent
    // use transition to slow it down so we can see what is happening
    // set transition duration to 0 to draw right away
    this.brush(d3.select(".brush"));
  }

  private updateScale(brushValue: number = 0) { 
    let { data } = this.props
    let filteredTickValues = getXAxisTickValues(data, brushValue, this.width);
    this.context.select(".x.axis").call(this.xAxis.tickValues(filteredTickValues));
  }

  private enter() {
    this.context.select(".brush").remove();
    this.context.append("g")
        .attr("class", "x brush")
        .call(this.brush)
      .selectAll("rect")
        .attr("y", -6)
        .attr("height", this.height + 7);

    this.context.selectAll(".axis.x")
      .attr("transform", "translate(0," + this.height + ")");
        
    this.context.selectAll('.subBar').attr(
        {
          height: (d: IItem) => {
            return this.height - this.y(d.value);
          },
          width: (d: IItem) => { 
            return this.x.rangeBand();
          },
          x: (d: IItem) => {
            return this.x(d.date);
          },
          y: (d: IItem) => {
            return this.y(d.value)
          }
        })
  }

  private subChart() {
    this.context.append("g")
        .attr("class", "x axis")
        .call(this.xAxis);
    
    let { data } = this.props;
    let subBars = this.context.selectAll('.subBar').data(data);
    subBars.enter()
      .append("rect")
      .classed('subBar', true);

    this.enter();
  }

  private brushed(): number[] {
    let { data } = this.props
    let ticks = this.x.domain();
    let selected = ticks.filter((d: string) => {
      return (this.brush.extent()[0] <= this.x(d)) && (this.x(d) <= this.brush.extent()[1]);
    });

    if(selected.length < 7) {
      return [];
    }
  
    if(this.brush.extent()[0] !== this.brush.extent()[1]) {
      let start = selected[0];
      let end = selected[selected.length - 1];

      return [ ticks.indexOf(start), ticks.indexOf(end) + 1 ];
    } else { 
      return [0, data.length];
    }
  }

  resize() {
    let { margin } = this.props;

    this.width = this.elWidth - margin.left - margin.right;
    this.height = this.elHeight - margin.top - margin.bottom;

    this.x.rangeBands([0, this.width], .1);
    this.y.range([this.height, 0]);
    this.brush.x(this.x);

    this.enter();
    this.updateScale();
  }
}