import React from 'react';

const Source: React.FC = () => {
  return (
    <div id="source" className="container fix-width">
      <div className="row">
        <div className="col-md-10 col-md-offset-1">
          <div className="source">
            Air quality data provided by <a href='https://sensors.africa/air/city/nairobi'>sensors.Africa</a>.
            <br/> Graphic elements sourced from <a href='https://www.freepik.com/'>freepik</a>.
            <br/> Project made possible by <a href='https://www.dw.com/en/dw-akademie/about-us/s-9519'>DW Akademie</a>.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Source;
