import { IItem, IMargin } from './interface';
import { LineChart } from './linechart';
import { Brush } from './brush';
import { ElChart } from './elchart';

declare let d3: any;
declare let $:any;

interface IProps {
  name: string,
  el: any
}

export class Chart extends ElChart {
  private props: IProps;
  private svg: any;

  private height: number = 0;
  private width: number = 0;

  private svgHeight: number = 0;
  private svgWidth: number = 0;

  private margin: IMargin;

  constructor(props: IProps) {
    super(props.el);

    this.props = props;
    this.margin = {
      top: 10,
      right: 75,
      bottom: 100,
      left: 100
    }

    this.init();
  }

  private async readData(type: number) {
    return new Promise<IItem[]>((resolve, reject) => {
      let fileName = "/assets/data/" + this.props.name + " P" + type + " values by day.csv";
      
      d3.csv(fileName, (data: IItem[]) => {
        data.forEach( (d) => {
          d.value = parseFloat(String(d.value))
        })

        resolve(data);
      })
    });
  }

  private async init() {
    let { name, el } = this.props
    let data1 = await this.readData(1);
    let data2 = await this.readData(2);

    this.svg = d3.select(el).append("svg");
    this.resize();
    
    let top1 = 45;
    let lineChart1 = new LineChart({
      data: data1,
      el,
      svg: this.svg,
      margin: {
        top: top1,
        right: this.margin.right,
        bottom: 300,
        left: this.margin.left
      },
      color: "#3C3837",
      showTicks: false,
      goodValue: 54,
      unhealthyValue: 255,
      lineColor: "#FFC000"
    });

    let top2 = 250;
    let lineChart2 = new LineChart({
      data: data2,
      el,
      svg: this.svg,
      margin: {
        top: top2,
        right: this.margin.right,
        bottom: 110,
        left: this.margin.left
      },
      color: "#FFC000",
      showTicks: true,
      goodValue: 12,
      unhealthyValue: 55.4,
      lineColor: "#3C3837"
    });

    new Brush({
      data: data1,
      el,
      svg: this.svg, 
      margin: {
        top: 430,
        right: this.margin.right,
        bottom: 20,
        left: this.margin.left
      }, 
      onBrushed: (brush: number[]) => {
        lineChart1.applyBrush(data1.slice(brush[0], brush[1]));
        lineChart2.applyBrush(data2.slice(brush[0], brush[1]));
      }
    })
    
    this.addText(["Micrograms per", "cubic meter"], 75, 20, false, false);
    this.addText([name, "Daily Average"], this.svgWidth - this.margin.right, 20, true);
    this.addText(["PM 10"], 10, top1);
    this.addText(["PM 2.5"], 10, top2);
  }

  private addText(text: string[], x: number, y: number, isRight: boolean = false, isBold: boolean = true) {
    text.forEach( (t, i) => {
      this.svg.append("text")
        .attr("class", "title" + (isBold ? " bold" : "") + (isRight ? " right" : ""))
        .attr("x", x)
        .attr("y", text.length > 1 ? 10 + (y * i) : (y * (i + 1)))
        .attr("dy", ".35em")
        .attr("text-anchor", isRight ? "end" : "start")
        .attr("font-weight", isRight ? "bold" : "normal")
        .text(t);
    })
  }

  resize() {
    this.width = this.elWidth - this.margin.left - this.margin.right;
    this.height = this.elHeight - this.margin.top - this.margin.bottom;

    this.svgHeight = this.height + this.margin.top + this.margin.bottom;
    this.svgWidth = this.width + this.margin.left + this.margin.right;

    this.svg
      .attr("width", this.svgWidth)
      .attr("height", this.svgHeight);

    this.svg.selectAll("text.title.right")
      .attr("x", this.svgWidth - this.margin.right)
  }
}