import React from 'react';

const Desc: React.FC = (props) => {
  return (
    <div className="col-md-10 col-md-offset-1">
      <div className="desc">
        {props.children}
      </div>
    </div>
  )
}

export default Desc;