import React from 'react';
import Section from './common/section';
import Heading from './common/heading';
import Image from './common/image';
import Desc from './common/desc';

const AirPolution101: React.FC = () => {
  return (
    <Section id="polution101">
      <Heading>Air pollution 101</Heading>
      <Desc>
        Of particular interest in air pollution is PM (particulate matter) 10 and PM2.5. PM10 refers to particles that are generally 10 micrometers or smaller. It’s mostly associated with road dust, construction activities and other coarse airborne particles that settle quickly.
      </Desc>
      <Desc>
        PM2.5 refers to fine inhalable particles that are 2.5 micrometers or smaller (As thin as a strand of human hair,). It’s mostly associated with burning material such as charcoal and petrol. PM2.5 stays longer in the air than heavier particles, increasing the chances of humans inhaling them deep into their lungs.
      </Desc>
      <Desc>
        Our noses are designed to block most airborne invaders (mainly dust and dirt), but not PM2.5. This makes PM2.5 deadly, because it’s microscopic enough to slip past the respiratory system and cause chronic illness. In Kenya, black carbon – sooty black material emitted from gas and diesel engines and other places that burn fossil fuel – is the major component of PM2.5. <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4449273/" target="_blank">One study </a>showed it constituted about a third of PM2.5 levels on roads.
      </Desc>
      <Image fileName="PM hair comparison.jpg"></Image>
      <Desc>
        To find out how toxic air quality is, the World Health Organization (WHO) developed the Air Quality Index (AQI), which tells us how clean or polluted our air is and what associated health effects may come about from it.
      </Desc>
      <Desc>
        AQI focuses on health effects you may experience within a few hours or days after breathing polluted air.
      </Desc>
      <Image marginTop={50} fileName="AQI.png"></Image>
      <Desc>
        It ranks air pollution between zero and 300. Zero to 50 is considered good air. Up to 50 is healthy for most people. Between 50 and 150 is ranked as moderate pollution. While this level is not considered deadly, extended exposure could be damaging to your lungs. It is also unhealthy for people with respiratory conditions such as asthma. Any air quality above 150 is unhealthy as per AQI. The index stops at 300. Any air at this level is considered hazardous and extended exposure will kill you.
      </Desc>
      <Desc>
        Generally, even if air pollution doesn’t kill you, it will most likely gradually break down your blood vessels and lungs.
      </Desc>
    </Section>
  )
}

export default AirPolution101;
