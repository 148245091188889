import React from 'react';
import Section from './common/section';
import SubHeading from './common/subheading';
import Desc from './common/desc';

const Nairobi: React.FC = () => {
  return (
    <Section id="nairobi">
      <SubHeading title="Nairobi" img="Nairobi.png"></SubHeading>
      <Desc>
        In the country’s most populous county, dangerously high PM2.5 concentrations are inextricably linked to some of Kenya’s most intractable problems: poverty, dysfunctional municipal services and corruption.
      </Desc>
      <Desc>
        At the world stage, Nairobi presents an image of a prosperous emerging metropolis, but behind the real estate boom and meandering highways, rampant poverty and continuing lack of functional infrastructure abounds.
      </Desc>
      <Desc>
        Roads are jammed daily with ageing second-hand vehicles, long-distance trucks and thousands of motorbikes filling the air with exhaust fumes, which the UN says could account for up to 40 per cent of the air pollution in the city.
      </Desc>
      <Desc>
        Various attempts have been made to make sense of the air quality in Nairobi, although they have been sparse and far between.
      </Desc>
      <Desc>
        From the little data that has been collected, air pollution in informal settlements and poor neighbourhoods is hazardously high. This is not surprising, given the long-running absence of waste-collection services by municipal authorities. Many communities are therefore forced to burn garbage in their vicinity, contributing further to an already risky situation.
      </Desc>
    </Section>
  )
}

export default Nairobi;
