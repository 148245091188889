import React from 'react';
import Section from './common/section';
import SubHeading from './common/subheading';
import Image from './common/image';
import Desc from './common/desc';
import Chart from './chart';
import Credit from './common/credit';

const Langata: React.FC = () => {
  return (
    <Section id="langata">
      <SubHeading title="Lang'ata" img="Langata.png"></SubHeading>
      <Desc>
        Langata is known to many as a middle-income suburb brimming with many aspiring and well-to-do Kenyans. What is clear from the data is that even such places are not exempt from the far-reaching presence of air pollution. 
      </Desc>
      <Chart name="Langata"></Chart>
      <Credit text="Data Source: ">
        <a href='https://sensors.africa/air/city/nairobi' target="_blank">Sensors Africa</a>
      </Credit>
      <Desc>
        Data from <a href='https://sensors.africa/air/city/nairobi' target="_blank">sensors.Africa</a>, a project that uses sensors to monitor air, water and sound pollution, shows that pollution levels increased dramatically in 2018, with highs of 208 for PM10 and PM2.5 levels of 106. A huge amount of air pollution happens between 5 and 9am. The second peak pollution episode is between 6 and 10pm, showing the effect of traffic in the area. 
      </Desc>
      <Image fileName="Langata Hours.png"></Image>
      <Credit text="Data Source: ">
        <a href='https://sensors.africa/air/city/nairobi' target="_blank">Sensors Africa</a>
      </Credit>
    </Section>
  )
}

export default Langata;
