import React from 'react';
import Section from './common/section';
import Heading from './common/heading';
import Image from './common/image';
import Desc from './common/desc';
import SourceLink from './common/sourcelink';
import Credit from './common/credit';

const WhyAirPolution: React.FC = () => {
  return (
    <Section id="why">
      <Desc>
        As human beings, we do certain things so much by default that they become second nature. Breathing is one of them. We run on oxygen by design and simply don’t think much of it, unless it is suddenly in short supply.
      </Desc>
      <Desc>
        About 250 million litres of air passes through the lungs of an average human being in their lifetime. And unless you’re fitted with an oxygen tank, almost all of this air is sourced from your immediate environment.
      </Desc>
      <Desc>
        This is where things get murky.
      </Desc>
      <Image fileName="Breathed Air.png"></Image>
      <Desc>
        The bulk of air on our planet today is compromised of many pollutants, ranging from dust and smoke to chemical fumes.
      </Desc>
      <Desc> 
        For instance, when you walk along a busy city street, you will typically inhale <a href="https://www.pressreader.com/uk/the-guardian-weekly/20181109/281586651610256" target="_blank">about 20 million particles </a> in one lungful, some of them smaller than the width of a human hair.
      </Desc>
      <Desc>
        Exposure to such pollution, which scientists call PM2.5, has become a big risk factor in Kenya that is silently killing thousands of people. According to the United Nations (UN), about 14,300 Kenyans die annually due to health conditions traceable to indoor air pollution.
      </Desc>
      <Image fileName="All Resp Diseases.jpg"></Image>
      <Credit text="Source: Economic Survey 2018">
      </Credit>
    </Section>
  )
}

export default WhyAirPolution;
