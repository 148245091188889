import { IItem } from "./interface";

declare let d3: any;

export const getXAxisTickValues = (data: IItem[], brushValue: number, width: number) => {
  let tickScale = d3.scale.pow().range([data.length / 15, 0]).domain([data.length, 0]).exponent(.5);

  if(brushValue === 0) {
    brushValue = width;
  }

  let tickValueMultiplier = Math.ceil(Math.abs(tickScale(brushValue))); 
  if(width <= 320) {
    tickValueMultiplier = 10 * tickValueMultiplier;
  } else if(width <= 425) {
    tickValueMultiplier = 4 * tickValueMultiplier;
  } else if(width <= 768) {
    tickValueMultiplier = 3 * tickValueMultiplier;
  }

  let filteredTickValues = data.filter((d, i) => {
    return i % tickValueMultiplier === 0
  }).map((d) => { 
    return d.date
  })
  
  return filteredTickValues;
}

export const getMaxYTick = (data: IItem[]) => {
  let max = d3.max(data, (d: IItem) => { return d.value;})
  max = Math.ceil(max / 10) * 10;

  if(max > 50) {
    max = Math.ceil(max / 100) * 100;
  }

  return max;
}

export const getYAxisTickValues = (data: IItem[]) => {
  let yTickValues = [];
  let max = getMaxYTick(data);

  let incr = max > 50 ? Math.round(max / 5) : 10;

  for(let i = 0; i <= max; i+= incr) {
    yTickValues.push(i);
  }

  return yTickValues;
}