import React from 'react';
import Section from './common/section';
import SubHeading from './common/subheading';
import Image from './common/image';
import Desc from './common/desc';
import Chart from './chart';
import Credit from './common/credit';

const Mukuru: React.FC = () => {
  return (
    <Section id="mukuru">
      <SubHeading title="Mukuru" img="Mukuru.png"></SubHeading>
      <Desc>
        The air pollution situation in the informal settlement of Mukuru Kwa Njenga is typical of the all-too-familiar tale of Kenyan problems being much harsher on the poor. The burning of garbage in Mukuru on two dumps – one in Viwandani for general waste and Usafi Waste Disposal Plant for medicinal waste – has led to several cases of residents, young and old, being hospitalised for upper tract respiratory infections.
      </Desc>
      <Desc>
        Furthermore, scientists are of the opinion that air pollution may be a major cause of the decrease in birth weight among residents of Mukuru. The University of Liverpool in the United Kingdom is <a href="https://europepmc.org/grantfinder/grantdetails?query=pi%3A%22Mortimer%2BK%22%2Bgid%3A%22MR%2FS009027%2F1%22%2Bga%3A%22Medical+Research+Council%22" target="_blank">currently doing a study </a>on this. 
      </Desc>
      <Desc>
        In many ways, the plight of Mukuru shows the violence of poverty as many of these people and their children have nowhere to go and have to keep painfully breathing their way to an early grave.
      </Desc>
      <Desc>
        Our data analysis shows that Mukuru Kwa Njenga recorded the highest amount of pollution recorded in Nairobi, with PM10 levels going as high 701 and PM2.5 levels as high as 574. 
      </Desc>
      <Chart name="Mukuru"></Chart>
      <Credit text="Data Source: ">
        <a href='https://sensors.africa/air/city/nairobi'>Sensors Africa</a>
      </Credit>
      <Desc>
        Pollution mostly begins in the early morning hours and it reaches, on average, a high of 150 for PM10 and 94 for PM2.5. 
      </Desc>
      <Credit text="Mukuru Kwa Njenga Hour Graph ">
      </Credit>
      <Image fileName="Mukuru Kwa Njenga Hours.png"></Image>
      
      <Credit text="Mukuru Kwa Njenga Week Graph ">
      </Credit>
      <Image fileName="Week Graph.png"></Image>
      
      <Credit text="Data Source: ">
        <a href='https://sensors.africa/air/city/nairobi' target="_blank">Sensors Africa</a>
      </Credit>
    </Section>
  )
}

export default Mukuru;
