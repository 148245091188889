import React from 'react';

const Home: React.FC = () => {
  return (
    <header id="home" className="welcome-area">
      <div className="header-top-area">
        <div className="logo"> 
          <a target="_blank" rel="noopener noreferrer" href="https://www.odipodev.com/">
            <img src="/assets/images/Odipodev Complete.png" alt="Odipodev"/>
          </a>
        </div>
        <div className="nav-icon">
        </div>
        <div className="logo right"> 
          <a target="_blank" rel="noopener noreferrer" href="https://www.dw.com/en/dw-akademie/about-us/s-9519">
            <img src="/assets/images/Made Possible by DW.png" alt="Made Possible by DW"/>
          </a>
        </div>
      </div>
      <div className="welcome-image-area">
        <div className="display-table">
          <div className="display-table-cell">
            <div className="header-text text-center">
              <div className="main-title">Kenya’s invisible plague</div>
              <div className="sub-title">
                How Nairobi residents are breathing to death
              </div>
              <div className="storyby">
                <span>Odanga Madung and Samer Ahmed</span>
                <span>January 8, 2020</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Home;
