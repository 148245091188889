import React from 'react';

const Image: React.FC<{fileName: string, marginTop?: number}> = (props) => {
  let style = props.marginTop ? { marginTop: props.marginTop } : {}
  return (
    <div style={style} className="image col-md-12 wow fadeInUp" data-wow-delay="0.3s">
      <img src={"/assets/images/" + props.fileName} alt={props.fileName} />
    </div>
  )
}

export default Image;