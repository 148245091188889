import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';

const Desc: React.FC<{tweetId: string}> = (props) => {
  return (
    <div className="col-md-12">
      <TwitterTweetEmbed tweetId={props.tweetId} />
    </div>
  )
}

export default Desc;