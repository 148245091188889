declare let $: any;

export abstract class ElChart {
  private el: any;

  constructor(el: any) {
    this.el = el;

    window.addEventListener('resize', (event) => {
      this.resize();
    });
  }

  abstract resize(): any

  protected get elHeight() {
    return 500;
  }

  protected get elWidth() {
    return $(this.el).width();
  }
}