import React from 'react';

const Credit: React.FC<{align?: string, text?: string}> = (props) => {
  const align: any = props.align || 'center';
  const text: string = props.text || '';
  
  return (
    <div className="col-md-12">
      <div className="credit-media" style={{textAlign: align}}>
        {text}
        {props.children}
      </div>
    </div>
  )
}

export default Credit;