import React from 'react';

const Heading: React.FC = (props) => {
  return (
    <div className="col-md-10 col-md-offset-1 section-heading">
      {props.children}
    </div>
  )
}

export default Heading;