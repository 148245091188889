import React from 'react';
import Section from './common/section';
import SubHeading from './common/subheading';
import Image from './common/image';
import Desc from './common/desc';
import Twitter from './common/twitter'
import Chart from './chart';
import Credit from './common/credit';

const Syokimau: React.FC = () => {
  return (
    <Section id="syokimau">
      <SubHeading title="Syokimau" img="Syokimau.png"></SubHeading>
      <Desc>
        Syokimau residential area in Machakos County arose after Syokimau Farm was subdivided into several smaller units, which were then sold off as single-user plots, eventually becoming what you see today. The area has grown rapidly in the past decade, as middle-class Kenyans look outside the city for places where they can make their dreams of finally owning homes come true. Today, Syokimau is also one of the most polluted areas in metropolitan Nairobi, according to our data.
      </Desc>
      <Desc>
        Peak times for pollution, according to the data, is between 3 and 8am. In this period, PM10 levels on average go as high as 97 and PM2.5 levels on average go as high as 18. The second peak time is between 7 and 10pm, which may be due to traffic.
      </Desc>
      <Image fileName="Syokimau Hours.png"></Image>
      <Chart name="Syokimau"></Chart>
      <Credit text="Data Source: ">
        <a href='https://sensors.africa/air/city/nairobi' target="_blank">Sensors Africa</a>
      </Credit>
      <Desc>
        Syokimau has perhaps one of the best-documented cases of what it looks like when citizens try to defend their right to clean air. Local residents have been engaged in court battles with Endmor Steel Millers company over pollution in the neighbourhood. The company started operations in 2016 less than a year after this, people were already visibly frustrated with its activities. 
      </Desc>
      <Desc>
        <i>A post from a disgruntled resident reads:</i>
      </Desc>
      <Desc>
        <div className="quote">
          “Limit pollution to the adjacent estates!!!! smoke and that choking smell is not good our kids are suffering. We might be children of a lesser God and NEMA (National Environment Management Authority) will never hear our plea but this company is run by human beings. Please be considerate to the rest of us. We need to live”. 
        </div>
      </Desc>
      <Desc>
        Many other residents have been vocal about their frustrations with the company.
      </Desc>
      <Twitter tweetId="883338595561422848"></Twitter>
      <Twitter tweetId="1134112358060740613"></Twitter>
      <Twitter tweetId="1092058037144166402"></Twitter>
      <Desc>
        Residents have even made recordings of some of the company's activities, showing how smoke bellows out from the factory and pours onto the surrounding areas. (Explore gallery below.)
      </Desc>
      <Desc>
        <div id="cp_widget_71a88752-0bc8-4b7b-8261-8ebae16b8392">...</div>
      </Desc>
      <Credit text="Credit: Ronnie Okul">
      </Credit>
      <Desc>
        Last January, Nema officers inspected the plant, and it was ordered to shut down on February 4. Endmor thereafter appealed the decision at the Nairobi Environment Tribunal and was subsequently reopened. Members of the Syokimau Residents Association (SRA) claim that the tribunal has been compromised and that they have been locked out of the subsequent air-quality audit process that was to follow. One of the members, who did not wish to be named, said:  
      </Desc>
      <Desc>
        <div className="quote">
          "The situation here is too bad. Endmor Steel has been playing dirty games and even after they were ordered to close after NEMA did their due diligence and a third party (SGS Group) was recommended to inspect the air quality but they locked us out of the factory”. 
        </div>
      </Desc>
      <Desc>
        The factory operates at night and creates a thick smog that hangs above the neighbourhood, making residents, especially children, sick with lung infections such as bronchitis.  
      </Desc>
      <Desc>
        <div className="quote">
          “Usually in the morning ontop of the car you’ll see some white content or soot. A lot of the time this stuff is very visible in our houses. Personally I worry a lot for my wellbeing. I have this cough that doesn’t seem to go away, I’m not really sure what it is,” said Ronnie Okul, who lives close to the factory. 
        </div>
      </Desc>
      <Desc>
        The Syokimau case points to a much wider picture, one where the SRA seems to be <a href="http://sra.or.ke/sra/wp-content/uploads/2019/08/News-Letter-Last-Issue.pdf" target="_blank">in constant engagement </a>and conflict with Nema and Machakos County to keep its environs clean.  
      </Desc>
      <Desc>
        Previously, the association managed to get Thermotec, an iron sheets manufacturer, to relocate due to excessive noise pollution, vibration and air pollution arising from the painting of iron sheets.
      </Desc>
      <Desc>
        All this is a symptom of the poor approach to urban planning by the Machakos County government.
      </Desc>
      <Desc>
        In February 2017, a court case between the SRA and the Machakos County government and Nema revealed the extent of this decay. The residents’ association <a href="http://kenyalaw.org/caselaw/cases/view/141028" target="_blank">accused</a> the county of inconsistent and unplanned developments. Nema was accused of not involving residents in environmental impact assessment and creating fictitious environmental impact assessment reports. 
      </Desc>
    </Section>
  )
}

export default Syokimau;
