import React from 'react';
import './App.css';

import Home from './component/home';
import WhyAirPolution from './component/whyairpolution';
import AirPolution101 from './component/airpolution101';
import HowBad from './component/howbad';
import Nairobi from './component/nairobi';
import Syokimau from './component/syokimau';
import Langata from './component/langata';
import Mukuru from './component/mukuru';
import TheEffect from './component/theeffect';
import Source from './component/source';
import Nav from './component/nav';
import Credit from './component/common/credit'
import Image from './component/common/image';

const App: React.FC = () => {
  return (
    <div className="App">
      <Home></Home>
      <Nav></Nav>
      <Credit align="right" text="Dandora dump in Nairobi. PHOEBE OKALL | NATION"></Credit>
      <WhyAirPolution></WhyAirPolution>
      <AirPolution101></AirPolution101>
      <HowBad></HowBad>
      <Nairobi></Nairobi>
      <Syokimau></Syokimau>
      <Langata></Langata>
      <Mukuru></Mukuru>
      <TheEffect></TheEffect>
      <Source></Source>
      <footer></footer>
    </div>
  );
}

export default App;
